%primary-font {
    font-family: futura-pt, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
}

%secondary-font {
    font-family: futura-pt, sans-serif;
    font-weight: 300;
    text-transform: none;
}

%main-font {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: $base-font-size;
    line-height: $base-font-size + 5;
    text-transform: none;
}

h1,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h2,
h3,
.secondary-font {
    @extend %secondary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body,
.main-font {
    @extend %main-font;
}

.main-text-colour {
    color: $body-font-color;
}

h1 {
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 20px;
}

h2 {
    font-size: $h2-font-size;
    line-height: $h2-font-size;
    margin-bottom: 10px;
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 10px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 10px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 10px;
}

blockquote {
    font-size: 30px;
    line-height: 30px;
    @extend %secondary-font;
    font-weight: 400;
    cite {
        font-style: normal;
        text-transform: none;
    }
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}

.page-leaf-background {
    background-color: $pale-leaf;
    @include full_width_colour($pale-leaf);
}


.buy-button-wrapper {
    background: $background-buy-section;
}

.pagination {
    li {
        a {
            color: $pink;
        }
    }
}

.separator {
    padding-top: 30px;
    border-top: 3px solid $section-border-top;
}

a {
    color: $body-font-color;
    &:hover {
        color: $pink;
    }
}

p {
    margin-bottom: 1em;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($pink, $white);
}

.primary-button {
    @extend %primary-font;
    @include siteButton($primary-color, $white);
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.buy-button.primary-button {
    background-color: $red;
    &:hover {
        background-color: $red;
        color: $white;
    }
}

.secondary-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
    font-size: 20px;
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    position: relative;
    margin: 20px 0 20px auto;
    @media (max-width: 767px) {
        margin: 32px auto;
    }
    a {
        text-transform: uppercase;
        font-weight: 500;
        position: relative;
        &::before{
            content: '';
            background-image: url("../img/left-arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            position :absolute;
            display: block;
            top: 50%;
            left: -35px;
            height: 23px;
            width: 23px;
            transform: translateY(-50%);
            filter: invert(39%) sepia(10%) saturate(1906%) hue-rotate(154deg) brightness(92%) contrast(84%);
        }
    }
}
