.book-detail {
    .supafolio-loader-container {
        .supafolio-loader {
            border-top-color: $primary-color;
        }
    }
    .button-asset {
        padding: 0;
        a {
            display: block;
            padding: 15px 30px;
            color: $white;
        }
        &--audio {
            padding: 10px 30px;
        }
        &:hover {
            a {
                color: $primary-color;
            }
        }
    }
    .related-books {
        &-title {
            padding-top: 30px;
            border-top: 3px solid $dark-blue;
            margin-top: 0;
            h1 {
                color: $red;
                font-size: 40px;
                text-transform: capitalize;
                font-weight: 300;
            }
        }
    }
}
