$blue: #74bcc8;
$lynch: #60808b;
$pink: #c65855;
$light-pink: #da8573;
$yellow: #fad96f;
$dark-blue: #406b7f;
$red: #c84140;
$black: #000;
$green: #88b984;
$light-green: #8cc1a4;
$pale-leaf: #c4cda8;
$white: white;
$max-width: 1060px;
$grey: #5f5f5f;
$orange: #cb4e34;
$purple: #8174b5;
$animation-time: 0.3s;
$section-border-top: $dark-blue;
$background-body: #e6e1d7;
$body-font-color: $black;
$primary-color: $dark-blue;
$secondary-color: $red;
$background-buy-section: rgba(64, 107, 127, 0.29);

//TODO Move to parent components
$facebook: #4a6ea9;
$facebookHover: #3a5786;
$twitter: #00aced;
$twitterHover: #0087ba;
$blogger: #ff5722;
$bloggerHover: #ff6838;

$assetBackgrounds: $pink, $blue, $orange, $green, $purple, $orange;

/* Header */

$header-background-color: $primary-color;
$header-background-image: url("https://supadu-io.imgix.net/harpercollins-sharoncreech/sharoncreech-site/header-background.png?q=50&w=0.7&auto=format")
  no-repeat center center;

/* NAVIGATION */

$nav-links-colour: $white;
$nav-links-colour-hover: $yellow;
$nav-current-link-colour: $yellow;
$nav-background-colour: $primary-color;

/* default font sizes */

$base-font-size: 16px;
$h1-font-size: 50px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 20px;
$h5-font-size: 16px;
