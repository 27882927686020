.asset-detail {
    &__category {
        font-size: $h4-font-size;
        margin: 32px 0 0;
    }
    &__title {
        font-size: $h2-font-size;
        line-height: 1.1;
        margin-bottom: 16px;
    }
    &__description {
        margin-bottom: 16px;
    }
    &__action {
        text-transform: uppercase;
        padding: 16px 32px;
    }
    &__related-title {
        text-transform: uppercase;
        font-weight: 500;
    }
}