.header {
  background-color: $header-background-color;
  background: $header-background-image;
  background-size: cover;
  &__logo {
    padding: 70px 0 10px 0;
    @include breakpoint(sm) {
      padding: 30px 0;
    }
  }

  .main-menu {
    li {
      @include breakpoint(md) {
        margin-right: 2.5rem;
      }
    }
  }
}
