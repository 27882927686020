.home-blockquote {
  blockquote {
    color: $white;
    font-size: 30px;
    line-height: 30px;
    @extend %secondary-font;
    @include breakpoint(md) {
      font-size: 60px;
      line-height: 60px;
    }
    cite {
      font-style: normal;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 20px;
      margin-top: 20px;
      @include breakpoint(md) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }
  .tns-outer {
    @include breakpoint(large-handset-landscape) {
      margin: 0 30px;
    }
    .tns-controls {
      button {
        color: $white;
        opacity: 1;
      }
    }
  }
}
