.social-medias {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  a {
    span {
      font-size: 25px;
      color: $white;
      width: 45px;
      height: 45px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 50%;
      margin: 0 10px;
      transition: $animation-time all ease;

      &.icon {
        &-twitter {
          background-color: $twitter;
          &:hover {
            background-color: $twitterHover;
          }
        }
        &-facebook {
          background-color: $facebook;
          &:hover {
            background-color: $facebookHover;
          }
        }
        &-blogger {
          background-color: $blogger;
          &:hover {
            background-color: $bloggerHover;
          }
        }
      }
    }
  }
}
