.assets-landing {
    max-width: none !important;
    background-color: $white;
    
    > .assets-list {
        max-width: 1280px;
        margin: 0 auto;
        padding: 16px;
    }

    .pagination__results-qty {
        display: none;
    }
}