.main-navigation {
    background-color: $nav-background-colour;
    .main-menu {
        li {
            a {
                color: $nav-links-colour;
                @extend %primary-font;
                font-size: 25px;
                line-height: 30px;
                &:hover {
                    color: $nav-links-colour-hover;
                }
                &.is-active {
                    color: $nav-links-colour-hover;
                }
            }
        }
    }
}